body {
    background: linear-gradient(to top left, rgb(20, 20, 20) 0%, rgb(22, 22, 22) 100%);
    background-repeat: no-repeat;
    min-height: 100vw;
    height: 100%;
}
body::-webkit-scrollbar {
    display: none;
}


.entry {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.6rem 1rem 1rem 1rem;
    /* margin: 0.18rem; */
    /* border-radius: 6px; */
    /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6); */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid black;
    font-family: Arial;
    font-size: 0.9rem;
}


.entry-hidden {
    display: none;
}

.entry:hover {
    background-color: rgba(218, 220, 255, 0.28);
}

.entry-title {
    font-size: 1rem;
    letter-spacing: 0.00rem;
    color: rgb(7, 7, 7);
    padding-top: 0.65rem;
    font-weight: 500;
}

.entry-section-2 {
    margin-top: 0.5rem;
    width: inherit;
    display: inline-flex;
    /* justify-content: space-between; */
    justify-content: left;
}

.entry-container {
    display: grid;
    grid-template-columns: 46rem;
    background-color: rgba(12, 12, 12, 0.8);
    /* background-color: rgba(127, 87, 0, 0.8); */
    /* background-color: rgba(6, 127, 0, 0.8); */
    padding: 0.0rem;
    border-radius: 0.1rem;
    margin-bottom: 1rem;
    margin-top: -1rem;
    box-shadow: 0px 1px 6px 1px rgb(0, 0, 0);
}

.search-bar {
    background-color: rgba(26, 26, 26, 0.4);
    padding: 0.5rem;
    margin: 0.8rem 0rem 0rem 0rem;
    border: none;
    border-radius: 0.1rem;
    color: rgba(255, 255, 255, 0.6);
    font-size: large;
    width: 30vw;
    outline: none;
    /* position: fixed; */
}
.search {
    background-color: rgba(12, 12, 12, 0);
    display: flex;
    flex-direction: row;
    position: fixed;
}
.search-icon {
    padding: 0.5rem 0.2rem 0.5rem 0.6rem;
    margin: 0.8rem 0rem 0rem 0rem;
    background-color: rgba(26, 26, 26, 0.4);
    color: rgba(255, 255, 255, 0.6);
}

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

.join-link {
    color: #f9f9f9;
    font-weight: 400;
    text-decoration: none;
    background-color: rgb(7, 7, 7);
    padding: 0.3rem 0.7rem 0.2rem 0.7rem;
    border-radius: 0.1rem;
    margin-left: 0.3rem;
    max-height: 1rem;
}

.join-link:visited {
    font-weight: 400;
    text-decoration: none;
}

.join-link:hover {
    font-weight: 400;
    text-decoration: none;
}

.join-link:active {
    font-weight: 400;
    text-decoration: none;
}

.clients {
    display: flex;
    background-color: rgb(7, 7, 7);
    align-items: center;
    justify-content: center;
    width: 4rem;
    color: white;
    padding: 0.1rem 0.0rem 0.2rem 0.1rem;
    border-radius: 0.1rem;
    max-height: 1.2rem;
}

.paradise-text-overlay {
    width: 220rem;
    position: absolute;
    transform: rotate(320deg) scale(120%);
    overflow: hidden;
    z-index: -100;
}

.section-heading {
    font-family: monospace;
    color: rgba(255, 255, 255, 0.6);
}

.navbar {
    background-color: rgba(12, 12, 12, 0.8);
    width: 100vw;
    float: top;
    position: fixed;
    display: flex;
    box-shadow: 0px 0px 6px 1px rgb(0, 0, 0);
    backdrop-filter: blur(6px);
}

.top-image {
    background-image: url("../public/Instagram-02.png");
    background-position: center center;
    background-size: 174%;
    height: 3rem;
    width: 3rem;
    border-radius: 0.6rem;
    margin: 0.5rem 0.8rem 0.5rem 0.8rem;
}

.org-name {
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.2rem;
    font-family: monospace;
    align-self: center;
}

.content {
    margin-top: 3.8rem;
}

.timer {
    margin-top: 1.25rem;
    margin-left: 0.7rem;
    height: 1.4rem;
    width: 1.4rem;
    color: white;
    /* scale: 26%; */
}
.timer-help {
    visibility: hidden;
    padding: 0.25em 0.5em;
    background-color: black;
    font-size: 0.8rem;
    position: fixed;
}
.timer:hover .timer-help{
    visibility: visible;
    transition-delay: 0.3s;
}

.timer-count {
    color: white;
    font-size: 2rem;
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    .search  {
        right: 1rem;
        width: 40%;
    }

    .entry-container {
        grid-template-columns: 30rem;
    }
}

@media only screen and (max-width: 576px) {
    .search {
        right: 3.4rem;
        width: 30%;
    }

    .entry-container {
        grid-template-columns: 20rem;
    }

    .org-name {
        width: 6rem;
    }
}